import React from 'react';
import Footer from './Footer';
import Header from './Header';
import SEO from './Seo';

const Layout = ({ children }) => {
  return (
    <>
      <SEO title="simpleheroes" description="IT is our superpower" />

      <Header />
      <main className="text-gray-900 bg-white">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
