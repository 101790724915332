import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../imgs/simpleheroes-logo.png';
import { Link } from "gatsby";

// import Button from '../Button';
import HButton from '../HeaderButton';

const Header = () => (

  <header className="sticky top-0 z-50">
    <div className="container bg-transparent flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="">
       <a href="https://www.simpleheroes.de"><img className="w-full sm:w-1/4 ms:w-4/6 md:w-2/6 z-50" src={LogoIcon} alt="simpleheroes Logo"/></a>
        </div>
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4 text-gray-800 font-bold" href="#mission">
          Mission
        </AnchorLink>
        <AnchorLink className="px-4 text-gray-800 font-bold" href="#services">
          Superkräfte
        </AnchorLink>
        <AnchorLink className="px-4 text-gray-800 font-bold" href="#referenzen">
          Heldentaten
        </AnchorLink>
        {/* <AnchorLink className="px-4 text-gray-800 font-bold opacityPulse-css" href="#contact">
          Heldenruf
        </AnchorLink> */}
      </div>
      <div className=" md:block">
          <Link className="px-4 text-gray-800 font-bold opacityPulse-css" to="/contact">
            <HButton className="opacityPulse-css text-sm bg-transparent rounded-full">Heldenruf</HButton>
          </Link>
      </div>
    </div>
  </header>
);

export default Header;
