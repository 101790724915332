export default [
  {
    // title: 'Long',
    // content:
    //   'Full Stack Web Developer, Project & Content Manager',
    customerName: 'Long',
    customerTitle: 'Web & Data Analyst',
    customerImage: 'https://simpleheroes.de/heroes/mLG.jpg'
  },
  {
    // title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    // content:
    //   'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Joe',
    customerTitle: 'Finance & PPC Experte',
    customerImage: 'https://simpleheroes.de/heroes/mJOE.jpg'
  },
  {
    // title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    // content:
    //   'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Don',
    customerTitle: 'Web & Security Spezialist',
    customerImage: 'https://simpleheroes.de/heroes/mDT.jpg'
  }
  
];
