export default [
  {
    // title: 'Long',
    // content:
    //   'Full Stack Web Developer, Project & Content Manager',
    customerName: 'Martin',
    customerTitle: 'Data & Research Spezialist',
    customerImage: 'https://simpleheroes.de/heroes/mTMT.jpg'
  },
  {
    // title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    // content:
    //   'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Nico',
    customerTitle: 'Sales & Product Manager',
    customerImage: 'https://simpleheroes.de/heroes/mNB2.jpg'
  },
  {
    // title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    // content:
    //   'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Nikita',
    customerTitle: 'Web & Research Spezialist',
    customerImage: 'https://simpleheroes.de/heroes/mNB.jpg'
  }
  
];
