import React from 'react';
import Button from '../components/Button';
import { Link } from "gatsby";

import CustomerCard from '../components/CustomerCard';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import heroes from '../data/customer-data';
import heroes2 from '../data/heroes-data';
import heroes3 from '../data/heroes-data-kani';

import DEV1 from "../imgs/DEV1.png";
import DEV2 from "../imgs/DEV2.png";
import CODE from "../imgs/CODE.png";
import MAP from "../imgs/MAP.png";
import SEO from "../imgs/SEO.png";
import ECO from "../imgs/ECO.png";
import OA from "../imgs/OA.png";
import ROSA from "../imgs/ROSA.png";
import DH from "../imgs/DH.png";
import FBA from "../imgs/FBA.png";
import LAUNCH from "../imgs/LAUNCH.png";
import KWS from "../imgs/KWS.png";

export default () => (
  <Layout>
    <section className="pt-40 md:pt-40 gradient">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none text-white">
          IT ist unsere Superpower
          </h1>
          <p className="text-xl lg:text-1xl mt-6 font-light text-white">
          Von der Brand Strategy zur Corporate Website und Online Shop

          </p>
          <p className="mt-8 md:mt-12">
          <Link to="/contact"><Button className="mx-auto lg:mx-0 bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg">Kostenloses Beratungsgespräch</Button></Link>
          </p>
          {/* <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> */}
        </div>
        <div className="lg:w-1/2">
          <img src={DEV1} className="pb-16" alt="development focusing on responsiveness and state-of-the-art technology"></img>
        </div>
      </div>
      <div className="relative -mt-12 lg:-mt-24">
              <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
              <path d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z" opacity="0.100000001"></path>
              <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-4" opacity="0.200000003"></path>
              </g>
              <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
              </g>
              </g>
              </svg>
        </div>
    </section>
    
    
{/* Alternate features */}
    <section id="mission" className="bg-white py-8">
              <div className="container max-w-5xl mx-auto m-8">
                <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">Unsere Mission</h2>
                <div className="w-full mb-4">	
                  <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                </div>
                <div data-sal="slide-left" data-sal-duration="2000" className="flex flex-wrap">
                  <div className="w-5/6 sm:w-1/2 p-6">
                    <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">Programmierung und Design sind unsere Superpower</h3>
                    {/* <p className="text-gray-600 mb-8">At simpleheroes we value attractive and modern looking websites with state-of-the-art security technologies and customer-attractive webdesign.<br /><br /> */}
                    {/* <p className="text-gray-600 mb-8">Um bestmögliche Resultate zu erreichen, haben wir uns auf die Entwicklung und Webprogrammierung mit WordPress und Onlineshops mit Shopware 5 spezialisiert. Nur so können wir maximale Kompetenz für die Erstellung und dessen weitere Betreuung garantieren.<br /><br /></p> */}
                    <p className="text-gray-600 mb-8">Wir wollen unsere Superkräfte einsetzen und dich bei der erfolgreichen Entwicklung und Umsetzung deiner Digitalstrategie unterstützen. Dabei reicht unser Powerspektrum als Helden der digitalen Welt von der strategischen Beratung über Customer-Experience Design bis hin zur Entwicklung von Prototypen, Corporate Websites und fertigen Web-Anwendungen und Online Shops.<br /><br /></p>
                  </div>
                  <div className="w-full sm:w-1/2 p-6">
                    <img className="w-full sm:h-64 mx-auto" src={CODE} alt="software development team working efficiently together"></img>
                  </div>
                </div>
                
                <div data-sal="slide-right"  data-sal-duration="2000" className="flex flex-wrap flex-col-reverse sm:flex-row">	
                  <div className="w-full sm:w-1/2 p-6 mt-6">
                    <img className="w-5/6 sm:h-64 mx-auto" src={MAP} alt="Our headquarters are based in Berlin and Saigon."></img>
                  </div>
                  <div className="w-full sm:w-1/2 p-6 mt-6">
                    <div className="align-middle">
                      <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">International agierend</h3>
                      <p className="text-gray-600 mb-8">Eine Digitalagentur mit Hauptquartieren wie ein Superheld: Wir bringen alles mit, was du brauchst und sind immer für dich da. Aus Berlin und Saigon sind wir jederzeit Verfügbar und bereit für den Heldenruf. Hinter unseren geheimen Identitäten als Superhelden kämpfen wir mit Herz und Leidenschaft gegen Fachchinesisch und für tadellose Webpräsenzen.<br /><br /></p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
     {/* Title cards */}
     <section id="services" className="bg-white py-8">
      <div className="container mx-auto flex flex-wrap pt-4 pb-12">
      
      <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">Unsere Superkräfte</h2>
        <div className="w-full mb-4">	
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
      
        <div data-sal="fade"  data-sal-duration="2000" className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <img className="w-full text-gray-600 text-xs md:text-sm px-6" src={ECO} alt="Search Engine Optimization, SEO"></img>
              <p className="w-full text-gray-600 text-xs md:text-sm px-6">OnlineShops & eCommerce</p>
              <div className="w-full font-bold text-xl text-gray-800 px-6">Shopware & Amazon</div>
              <p className="text-gray-800 text-base px-6 mb-5">
              Wir verstehen, dass dein Online Shop eine hohe Schnittstellenkompetenz und Prozesssicherheit mitbringen muss. Durch unsere jahrelange Erfahrung wissen wir, worauf es ankommt.
              </p>
          </div>
          {/* <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div className="flex items-center justify-start">
              <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">Action</button>
            </div>
          </div> */}
        </div>
        
        <div data-sal="fade"  data-sal-duration="2000" className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
            <img className="w-full text-gray-600 text-xs md:text-sm px-6" src={DEV2} alt="developers using frontend technologies like HTML, CSS and JavaScript and backend technologies like PHP or C++."></img>
              <p className="w-full text-gray-600 text-xs md:text-sm px-6">Web- & mobile App Entwicklung</p>
              <div className="w-full font-bold text-xl text-gray-800 px-6">Programmierung</div>
              <p className="text-gray-800 text-base px-6 mb-5">
              Mit der Spezialisierung auf die Entwicklung und Programmierung mit WordPress und Onlineshops mit Shopware 5 können wir maximale Kompetenz für die Erstellung und dessen weitere Betreuung garantieren.
              </p>
          </div>
          {/* <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div className="flex items-center justify-center">
              <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">Action</button>
            </div>
          </div> */}
        </div>
  
        <div data-sal="fade"  data-sal-duration="2000" className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
            <img className="w-full text-gray-600 text-xs md:text-sm px-6" src={SEO} alt="We help you to create more visibility."></img>
              <p className="w-full text-gray-600 text-xs md:text-sm px-6">Marketing und Sichtbarkeit</p>
              <div className="w-full font-bold text-xl text-gray-800 px-6">Social Media & SEO </div>
              <p className="text-gray-800 text-base px-6 mb-5">
              Was nutzt schon die schönste Webseite, wenn Deine Kunden Dich nicht finden können? Wir wollen unsere Kräfte dazu nutzen und deine Webpräsenz und Sichtbarkeit erhöhen!</p>
          </div>
          {/* <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div className="flex items-center justify-end">
              <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">Action</button>
            </div>
          </div> */}
        </div>

        <div data-sal="fade"  data-sal-duration="2000" className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
            <img className="w-full text-gray-600 text-xs md:text-sm px-6" src={LAUNCH} alt="Wir helfen dir dein Amazon FBA Business aufzubauen."></img>
              <p className="w-full text-gray-600 text-xs md:text-sm px-6">Produkt & Keyword Analysen</p>
              <div className="w-full font-bold text-xl text-gray-800 px-6">Amazon Produkt Launch</div>
              <p className="text-gray-800 text-base px-6 mb-5">
              Vor der Produkteinführung auf Amazon überprüfen wir deine Keywords und stellen sicher, dass dein Listing vollständig optimiert ist.
              </p>
          </div>
          {/* <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div className="flex items-center justify-center">
              <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">Action</button>
            </div>
          </div> */}
        </div>
        <div data-sal="fade"  data-sal-duration="2000" className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <img className="w-full text-gray-600 text-xs md:text-sm px-6" src={FBA} alt="Amazon Fulfillment by Amazon"></img>
              <p className="w-full text-gray-600 text-xs md:text-sm px-6">Ebay & Amazon</p>
              <div className="w-full font-bold text-xl text-gray-800 px-6">Amazon FBA</div>
              <p className="text-gray-800 text-base px-6 mb-5">
              Wir helfen dir dein Amazon FBA Business aufzubauen und unterstützen dich angefangen beim Product-Sourcing, Listing verfassen bis perfekten Launch mit optimalen Keywords und PPC Kampagnen auf Amazon zu ranken.
              </p>
          </div>
          {/* <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div className="flex items-center justify-start">
              <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">Action</button>
            </div>
          </div> */}
        </div>
        
       
  
        <div data-sal="fade"  data-sal-duration="2000" className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
            <img className="w-full text-gray-600 text-xs md:text-sm px-6" src={KWS} alt="Keywords, Keywordanalysen, Keywordalgorithmus, Amazon's Choice, Ranking"></img>
              <p className="w-full text-gray-600 text-xs md:text-sm px-6">Keyword & PPC Analysen</p>
              <div className="w-full font-bold text-xl text-gray-800 px-6">Amazon Ranking Algorithmus </div>
              <p className="text-gray-800 text-base px-6 mb-5">
              Für einen Platz auf den oberen Rängen müssen viele Parameter beachtet werden. Wir helfen dir dabei die richtigen Keywords zu finden, den Amazon Algorithmus besser zu verstehen und Amazon's Choice zu werden.</p>
          </div>
          {/* <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
            <div className="flex items-center justify-end">
              <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">Action</button>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Unser Fokus</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Amazon FBA</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Branding</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Beratung</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
        </div>
      </div> */}
    </section>

    <section id="referenzen" className="pb-20">
      
    </section>
    <section className="w-full bg-white">
    <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">Heldentaten</h2>
    <div className="w-full mb-4"><div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div></div>
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight text-center">
            Der Online Shop eines Streetwear Labels 
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed text-center">
          <span className="font-semibold">"OVA ALL" Berlin</span> ist ein junges Unternehmen, welches mit einem Shopware Online Shop unterstützt wird. 
          </p>
        </div>
      }
      secondarySlot={<a href="https://www.ova-all.com"><img data-sal="slide-left"  data-sal-duration="2000" src={OA} alt="OnlineShop created with Shopware 5"/></a>}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight text-center">
            Das Hotel Unternehmen in Vietnam
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed text-center">
          <span className="font-semibold">"Rosa Hotel & Spa Group"</span> gehört zu den top Hotel & Wellness Orten Saigon's und wurde mittels populären Wordpress Technologien umgesetzt.
          </p>
        </div>
      }
      secondarySlot={<a href="https://rosahotelspa.com/"><img data-sal="slide-right"  data-sal-duration="2000" src={ROSA} alt="Hotel and Spa Company in Vietnam"/></a>}
    />
   
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight text-center">Ein Netzwerk für Entwickler</h3>
          <p className="mt-8 text-xl font-light leading-relaxed text-center">
            Das <span className="font-semibold">"Devinitelyhealthy"</span> Network basiert auf VanillaJS & ReactJS und ist ein hervorragendes Beispiel eines simplen und ansprechenden Designs!
          </p>
        </div>
      }
      secondarySlot={<a href="https://www.devinitelyhealthy.xyz/"><img data-sal="slide-left"  data-sal-duration="2000" src={DH} alt="devinitelyhealthy network page"/></a>}
    />
    </section>
    <section data-sal="slide-down" data-sal-duration="2000" id="heroes" className="py-10 lg:py-10">
      <div className="container mx-auto">

      <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800 pb-6">Die Heroes</h2>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {heroes.map(hero => (
            <div key={hero.customerName} className="flex-1 px-3">
              <CustomerCard customer={hero} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section data-sal="slide-up" data-sal-duration="2000" id="heroes2" className="py-10 lg:py-10">
      <div className="container mx-auto">
        {/* <LabelText className="mb-8 text-gray-600 text-center">Die Helden</LabelText> */}
        <div className="flex flex-col md:flex-row md:-mx-3">
          {heroes2.map(hero => (
            <div key={hero.customerName} className="flex-1 px-3">
              <CustomerCard customer={hero} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section data-sal="slide-up" data-sal-duration="2000" id="heroes3" className="py-10 lg:py-10">
      <div className="container mx-auto">
        {/* <LabelText className="mb-8 text-gray-600 text-center">Die Helden</LabelText> */}
        <div className="flex flex-col md:flex-row md:-mx-3">
          {heroes3.map(hero => (
            <div key={hero.customerName} className="flex-1 px-3">
              <CustomerCard customer={hero} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section id="contact" className=" gradient mx-auto bg-gray-200 rounded-lg text-center">
        {/* Waves SVG */}
        <svg className="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
                  <g className="wave" fill="#f8fafc">
                    <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                  </g>
                  <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                    <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                      <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                      <path d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z" opacity="0.100000001"></path>
                      <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" opacity="0.200000003"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
      {/* <h3 className="text-5xl font-semibold text-white">Sende uns einen Heldenruf!</h3> */}
      <h2 data-sal="fade"  data-sal-duration="2000"  className="w-full my-2 text-4xl font-bold leading-tight text-center text-white">Sende uns einen Heldenruf!</h2>
            <div className="w-full mb-4">	
              <div className="h-1 mx-auto bg-white w-64 opacity-25 my-0 py-0 rounded-t text-white"></div>
            </div>
      {/* <p className="mt-8 text-xl font-light text-white">..oder eine Nachricht.</p> */}
      <p className="mt-8 text-xl font-light text-white">Wir melden uns innerhalb 24 Stunden!</p>
      <p className="mt-8">
        <Link className="text-black hover:text-black" to="/contact"><Button className="alertPulse-css mx-auto lg:mx-0 bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg">Kontakt</Button></Link>
      </p>
      <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
              <path d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z" opacity="0.100000001"></path>
              <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-5" opacity="0.200000003"></path>
              </g>
              <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
              </g>
              </g>
              </svg>
    </section>
  </Layout>
);
