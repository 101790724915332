export default [{
        //   title: 'Long',
        // content:
        //   'Full Stack Web Developer, Project & Content Manager',
        customerName: 'Kai',
        customerTitle: 'Web & Design Spezialist',
        customerImage: 'https://simpleheroes.de/heroes/mKL.jpg'
    },
    {
        // title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
        // content:
        //   'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
        customerName: 'Larissa',
        customerTitle: 'Marketing & Design Experte',
        customerImage: 'https://simpleheroes.de/heroes/mLR.jpg'
    },

];